
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';

@Options({
	name: 'PackageView',
	components: {},
})
export default class PackageView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: '0' }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	public PackageViewMode = PackageViewMode;
	get item(): Item | null {
		return orderModule.openItem;
	}
	mounted(): void {
		// probably should modify this to rebuild the orderModule openItem from url
		// but for now just go back if the page is refreshed
		if (!orderModule.openItem) {
			this.$router.push(`/portal/${this.clientLocationKey}`);
		}
	}
	getImage(): string {
		if (!this.item) {
			return '';
		}
		return this.item?.PhotoDomain + 'w_800,h_400,c_fill' + '/v' + this.item?.PhotoVersion + this.item?.PhotoFolder + '/original';
	}
	getOptions(): string[] {
		var res: string[] = [];
		this.item?.Activities.forEach((entity) => {
			if (this.item?.PackageKey) {
				res.push(entity.Name);
			}
			if (entity.Difficulty) {
				res.push(`*${entity.Difficulty}`);
			}
			if (entity.Duration) {
				res.push(`*${entity.Duration}`);
			}
			if (entity.AgeMinimum) {
				res.push(`*${entity.AgeMinimum}`);
			}
			if (entity.MinHeight) {
				res.push(`*${entity.MinHeight}`);
			}
		});
		return res;
	}
}
